import { default as googleF8EYMoo1GBMeta } from "/opt/buildhome/repo/pages/auth/google.vue?macro=true";
import { default as cancelSZDNQ7Ay04Meta } from "/opt/buildhome/repo/pages/cancel.vue?macro=true";
import { default as index_32copy_322fGdZP37xRjMeta } from "/opt/buildhome/repo/pages/index copy 2.vue?macro=true";
import { default as index_32copypzNX6ATsZnMeta } from "/opt/buildhome/repo/pages/index copy.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as login4dfr6kRqBoMeta } from "/opt/buildhome/repo/pages/login.vue?macro=true";
import { default as policieseZkaYL7Tr7Meta } from "/opt/buildhome/repo/pages/policies.vue?macro=true";
import { default as successBVIqjShRzzMeta } from "/opt/buildhome/repo/pages/success.vue?macro=true";
import { default as termsjw1825O2ZdMeta } from "/opt/buildhome/repo/pages/terms.vue?macro=true";
export default [
  {
    name: "auth-google",
    path: "/auth/google",
    component: () => import("/opt/buildhome/repo/pages/auth/google.vue").then(m => m.default || m)
  },
  {
    name: "cancel",
    path: "/cancel",
    component: () => import("/opt/buildhome/repo/pages/cancel.vue").then(m => m.default || m)
  },
  {
    name: "index copy 2",
    path: "/index%20copy%202",
    component: () => import("/opt/buildhome/repo/pages/index copy 2.vue").then(m => m.default || m)
  },
  {
    name: "index copy",
    path: "/index%20copy",
    component: () => import("/opt/buildhome/repo/pages/index copy.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/buildhome/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: login4dfr6kRqBoMeta || {},
    component: () => import("/opt/buildhome/repo/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "policies",
    path: "/policies",
    meta: policieseZkaYL7Tr7Meta || {},
    component: () => import("/opt/buildhome/repo/pages/policies.vue").then(m => m.default || m)
  },
  {
    name: "success",
    path: "/success",
    component: () => import("/opt/buildhome/repo/pages/success.vue").then(m => m.default || m)
  },
  {
    name: "terms",
    path: "/terms",
    component: () => import("/opt/buildhome/repo/pages/terms.vue").then(m => m.default || m)
  }
]