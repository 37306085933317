import revive_payload_client_KxKo3WaI1E from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_typescript@5.5.3_vite@5.3.4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_lHz3WmLmaG from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_typescript@5.5.3_vite@5.3.4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_xICPeZ3Qbp from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_typescript@5.5.3_vite@5.3.4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import supabase_client_xN1bvT0pFK from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+supabase@1.3.5/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.js";
import payload_client_SG3rMJnvJg from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_typescript@5.5.3_vite@5.3.4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_jmBgJXYB66 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_typescript@5.5.3_vite@5.3.4/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_jb4bL8VN7b from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_typescript@5.5.3_vite@5.3.4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_9SjQxkr6J7 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_typescript@5.5.3_vite@5.3.4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_1OKTzExvic from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_typescript@5.5.3_vite@5.3.4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_gyX6QhdXAH from "/opt/buildhome/repo/node_modules/.pnpm/dayjs-nuxt@2.1.9/node_modules/dayjs-nuxt/dist/runtime/plugin.mjs";
import plugin_dXIG1LgK4v from "/opt/buildhome/repo/node_modules/.pnpm/@hebilicious+vue-query-nuxt@0.3.0_@tanstack+vue-query@5.51.9_nuxt@3.12.4/node_modules/@hebilicious/vue-query-nuxt/dist/runtime/plugin.mjs";
import auth_redirect_37V93RytoR from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+supabase@1.3.5/node_modules/@nuxtjs/supabase/dist/runtime/plugins/auth-redirect.js";
import plugin_client_kl1esEKGoh from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-viewport@2.1.5_vue@3.4.33/node_modules/nuxt-viewport/dist/runtime/plugin.client.mjs";
import slideovers_DHKMmPOgWY from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.18.2_vite@5.3.4_vue@3.4.33/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_jJIW8s3nuk from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.18.2_vite@5.3.4_vue@3.4.33/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_JSNsxpCXS6 from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.18.2_vite@5.3.4_vue@3.4.33/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_5OGoPfE5jR from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+color-mode@3.4.2/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_3hdcsNCUYp from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+icon@1.3.1_vite@5.3.4_vue@3.4.33/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import firebase_Wjz9XrZo01 from "/opt/buildhome/repo/plugins/firebase.ts";
import vue_mountable_fz1MuxJRc2 from "/opt/buildhome/repo/plugins/vue-mountable.js";
export default [
  revive_payload_client_KxKo3WaI1E,
  unhead_lHz3WmLmaG,
  router_xICPeZ3Qbp,
  supabase_client_xN1bvT0pFK,
  payload_client_SG3rMJnvJg,
  navigation_repaint_client_jmBgJXYB66,
  check_outdated_build_client_jb4bL8VN7b,
  chunk_reload_client_9SjQxkr6J7,
  components_plugin_KR1HBZs4kY,
  prefetch_client_1OKTzExvic,
  plugin_gyX6QhdXAH,
  plugin_dXIG1LgK4v,
  auth_redirect_37V93RytoR,
  plugin_client_kl1esEKGoh,
  slideovers_DHKMmPOgWY,
  modals_jJIW8s3nuk,
  colors_JSNsxpCXS6,
  plugin_client_5OGoPfE5jR,
  plugin_3hdcsNCUYp,
  firebase_Wjz9XrZo01,
  vue_mountable_fz1MuxJRc2
]